<template>
  <div class="card">
    <div class="card-header">
     <div class="d-flex align-items-center">
      <h3> Paket Detayları </h3>
     </div>
    </div>
    <div class="card-body">
        <div class="mb-10">
            <!--begin::Label-->
            <label class="form-label">Meta Başlık</label>
            <!--end::Label-->
            <!--begin::Input-->
            <input v-if="paket.meta" type="text" class="form-control mb-2" v-model="paket.meta.title" placeholder="Meta tag name" />
            <!--end::Input-->

        </div>
        <!--begin::Label-->
        <label class="form-label">Meta Açıklama</label>
        <!--end::Label-->

        <!--begin::Input group-->
        <div class="mb-10">
            <el-input
                v-if="paket.meta"
                v-model="paket.meta.description"
                :autosize="{ minRows: 3, maxRows: 20 }"
                type="textarea"
                placeholder="Meta Açıklaması Giriniz!"
            />
        </div>
        <!--end::Input group-->
        <!--begin::Input group-->
        <div>
            <!--begin::Label-->
            <label class="form-label">Meta Keywords</label>
            <!--end::Label-->

          <div>
              <input v-if="paket.meta" type="text" class="form-control mb-2" v-model="paket.meta.keywords" placeholder="Meta tag name" />
          </div>
        </div>



    </div>
  </div>

</template>


<script>
import { ElInput } from 'element-plus';
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import store from '@/store';
import  useCrud  from '@/composables/useCrud.js';

import { ref, onMounted } from 'vue';
export default {
    

    setup() {

        const paket = ref({});
        const options = ref([]);
        const productCategories = ref([]);

        const ct = ref([]);
        const { loadCrud } = useCrud();


        onMounted(() => {
            paket.value = store.getters.packageItem;
            loadCrud('categories').then(function(response){
                if(response.success){
                    response.data.forEach((value)=> {
                      productCategories.value.push({label:value.title,value:value.categoryId});
                    });
                }
            });
        });

        return {
          paket,
          options,
          productCategories,
          ct,
        }
      
    }
}
</script>